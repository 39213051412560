import * as React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import GoogleSingleMap from "../../map/google/property-detail-map"
import "./Map.scss"
// styles

// markup
const Map = ( props ) => {
  const [mapshow, setMapshow] = React.useState(false);
  React.useEffect(()=>{
    document.getElementById("clicktoShowmap")?.addEventListener('click',function (){
      setMapshow(true)
     }  );
     document.getElementById("newhomesMap")?.addEventListener('click',function (){
      setMapshow(true)
     }  );
  },[])
  
  return (
    mapshow && props?.property_details_map_data &&
    <React.Fragment>
      <div className="property-details-map-wrap">
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          { props?.property_details_map_data?.latitude && props?.property_details_map_data?.longitude &&
            <GoogleSingleMap lat={props?.property_details_map_data?.latitude} lng={props?.property_details_map_data?.longitude} height={"650px"} mapshow={mapshow}/>
          }
        </ScrollAnimation>
      </div>
    </React.Fragment>
  )
}

export default Map